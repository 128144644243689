const appDetails = {
	webLink: "https://slgrainsfs.graindiscovery.com",
	googlePlayLink:
		"https://play.google.com/store/apps/details?id=com.graindiscovery.marketplace_flutter.stlawrence",
	appStoreLink:
		"https://apps.apple.com/ca/app/st-lawrence-grains/id1592529609?uo=4",
	landingPage: "home",
	signUpButton: true
};

export default appDetails;
