import React from "react";
import SignUpButton from "../../components/button/SignupButton";
import Map, { CustomMap } from "../../components/map/Map";
import uuid from "react-uuid";
import Config from "../../config/config";
import { Helmet } from "react-helmet";

function Home() {
	return (
		<div
			className="w-full space-y-52 pt-64 pb-16 px-16 relative"
			style={{ background: Config?.styles?.backgroundColor }}
		>
			{process.env.REACT_APP_COMPANY_NAME === "bacresGrain" && (
				<Helmet>
					<meta http-equiv="refresh" content="0; url=https://www.bacresgrain.ca" />
				</Helmet>
			)}
			{process.env.REACT_APP_EFT_LINK_WESTLOCK === 'true' && (
				<a className="absolute top-0 right-0 m-4 pointer z-50" href="https://www.tronia.com/westlockterminals/">Login here to retrieve EFT payment number</a>
			)}
			{process.env.REACT_APP_EFT_LINK_SLG === 'true' && (
				<a className="absolute top-0 right-0 m-4 pointer z-50" href="https://www.tronia.com/stlawrence">Customer Account Login</a>
			)}
			<Landing />
			<OurStory />
			{typeof Config.location.teamProfile !== "undefined" &&
				typeof Config.location.addresses !== "undefined" &&
				Config.location.teamProfile.length !== 0 &&
				Config.location.addresses.length !== 0 ? (
				<Team />
			) : (
				Config.aboutUs.showHomePageContact && <Contact />
			)}
            {Config?.aboutUs?.showHomePageMap && (
                <div className="flex rounded-3xl overflow-clip">
                    <Map />
                </div>
            )}
		</div>
	);
}

function Landing() {
	return (
		<section
			id="landing"
			className="flex flex-col-reverse md:flex-row w-full justify-center md:space-x-20"
		>
			<div className="flex flex-col w-full pt-6 md:pt-0 md:w-1/2">
				<h1
					className="text-6xl font-bold"
					style={{ color: Config?.styles?.headerFontColor || Config?.styles?.primaryColor }}
				>
					{Config?.aboutUs?.title}
				</h1>
				<p className="py-3 text-lg">
					{typeof Config?.aboutUs?.landing !== "undefined"
						? Config?.aboutUs?.landing.map((paragraph) => (
							<p className="mt-3" style={{ color: `${Config?.styles?.paragraphFontColor || '#000'}` }}>
								{paragraph}
							</p>
						))
						: "We are excited to announce the release of our new farmer app! You can easily view live bids, sell grains, create target offers and more right from your smartphone."}
				</p>
				<div>
					{Config?.aboutUs.joinUs && (
						<SignUpButton
							title="Join Us Today"
							href="/signup"
							backgroundColor={Config?.styles?.secondaryVariant}
							textColor={Config?.styles?.btnTextColor}
						/>
					)}
				</div>
			</div>
			<div className="h-content w-full md:w-3/4 shadow-lg rounded-2xl overflow-hidden">
				<img
					className="object-cover"
					src={Config?.styles?.homepageBackground}
					alt={Config?.aboutUs?.title + "home image"}
				></img>
			</div>
		</section>
	);
}

function OurStory() {
	return (
		Config.aboutUs.paragraphs.length > 0 && (
			<section
				id="ourStory"
				className="flex flex-col-reverse md:flex-row w-full justify-center items-center md:space-x-20"
			>
				<div
					className={
						Config.aboutUs.paragraphs.length > 3
							? "h-content w-full md:w-2/4 shadow-lg rounded-2xl overflow-hidden"
							: "h-content w-full md:w-3/4 shadow-lg rounded-2xl overflow-hidden"
					}
				>
					<img
						className="object-fill"
						src={Config.styles.ourStoryImg}
						alt={Config.aboutUs.title + "home image"}
					></img>
				</div>
				<div className="flex flex-col w-full md:pt-0 md:w-1/2">
					<h1
						className="text-6xl font-bold"
						style={{ color: Config?.styles?.headerFontColor || Config?.styles?.primaryColor }}>
						Our Story
					</h1>
					{Config.aboutUs.paragraphs.map((paragraph) => (
						<p key={uuid()} className="pt-3 pb-6 text-lg" style={{ color: `${Config?.styles?.paragraphFontColor || '#000'}` }}>
							{paragraph}
							<br></br>
						</p>
					))}
				</div>
			</section>
		)
	);
}

function Contact() {
	return (
		<section id="contact" className="flex w-full flex-col">
			<h1
				className="text-6xl font-bold"
				style={{ color: Config.styles.primaryColor }}
			>
				Contact
			</h1>
			<div className="flex flex-col tablet:flex-row justify-around p-12 space-y-4 tablet:space-y-0">
				<ContactSection
					type="phone"
					iconColor={Config.styles.primaryColor}
					detail={Config.location.phone}
				/>
				<ContactSection
					type="email"
					iconColor={Config.styles.primaryColor}
					detail={Config.location.email}
				/>
				<ContactSection
					type="location"
					iconColor={Config.styles.primaryColor}
					detail={Config.location.address1}
					detail2={Config.location.address2}
				/>
			</div>
			{Config?.location?.hours?.map((hour) => (
				<div key={hour.type} className="flex justify-center items-center mb-4">
					<h1 className="text-xl font-bold mr-3">{hour.type} - </h1>
					<p className="text-lg">&nbsp;{hour.days}&nbsp;</p>
					<p className="text-lg">&nbsp;{hour.hours}&nbsp;</p>
				</div>
			))}
			<div className="flex rounded-3xl overflow-clip">
				<Map />
			</div>
		</section>
	);
}

function ContactSection(props) {
	return (
		<div className="flex flex-col justify-start items-center">
			{props.type === "email" && (
				<svg
					width="80"
					height="80"
					viewBox="0 0 80 80"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M36.2582 1.09443C38.5613 -0.364807 41.4386 -0.364812 43.7418 1.09443L78.2345 22.9485C79.3298 23.6425 80 24.8924 80 26.2412V68.4803C80 74.8424 75.1158 80 69.0909 80H10.9091C4.88417 80 0 74.8424 0 68.4803V26.2412C0 24.8924 0.670177 23.6425 1.76547 22.9485L36.2582 1.09443ZM69.2958 26.2412L40 7.67983L10.7042 26.2412L40 44.8026L69.2958 26.2412ZM72.7273 33.0232L43.7418 51.388C41.4387 52.8473 38.5614 52.8473 36.2582 51.388L7.27273 33.0232V68.4803C7.27273 70.601 8.90078 72.3202 10.9091 72.3202H69.0909C71.0992 72.3202 72.7273 70.601 72.7273 68.4803V33.0232Z"
						fill={props.iconColor}
					/>
				</svg>
			)}
			{props.type === "location" && (
				<svg
					width="80"
					height="80"
					viewBox="0 0 80 80"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M39.9999 20C32.6361 20 26.6666 25.9695 26.6666 33.3333C26.6666 40.6971 32.6361 46.6667 39.9999 46.6667C47.3637 46.6667 53.3332 40.6971 53.3332 33.3333C53.3332 25.9695 47.3637 20 39.9999 20ZM33.3333 33.3333C33.3333 29.6514 36.318 26.6667 39.9999 26.6667C43.6818 26.6667 46.6666 29.6514 46.6666 33.3333C46.6666 37.0152 43.6818 40 39.9999 40C36.318 40 33.3333 37.0152 33.3333 33.3333Z"
						fill={props.iconColor}
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M39.9999 0C21.6615 0 6.66666 14.5551 6.66666 32.6667C6.66666 44.8891 13.9876 56.6634 21.2209 65.0881C24.8942 69.3664 28.6842 72.9436 31.8073 75.475C33.3645 76.7371 34.8001 77.7752 36.0078 78.5178C36.6078 78.8868 37.2059 79.2165 37.7712 79.4653C38.2295 79.6669 39.0624 80 39.9999 80C40.9374 80 41.7704 79.6669 42.2286 79.4653C42.7939 79.2165 43.392 78.8868 43.992 78.5178C45.1997 77.7752 46.6353 76.7371 48.1925 75.475C51.3156 72.9436 55.1056 69.3664 58.7789 65.0881C66.0122 56.6634 73.3332 44.8891 73.3332 32.6667C73.3332 14.5551 58.3384 0 39.9999 0ZM13.3333 32.6667C13.3333 18.3775 25.2013 6.66667 39.9999 6.66667C54.7985 6.66667 66.6665 18.3775 66.6665 32.6667C66.6665 42.4442 60.6542 52.6699 53.7208 60.7453C50.3108 64.7169 46.8092 68.0147 43.9948 70.2959C42.5833 71.4399 41.3887 72.2925 40.5 72.8389C40.3098 72.9559 40.1432 73.0528 39.9999 73.1321C39.8566 73.0528 39.6901 72.9559 39.4998 72.8389C38.6112 72.2925 37.4166 71.4399 36.0051 70.2959C33.1907 68.0147 29.689 64.7169 26.279 60.7453C19.3457 52.6699 13.3333 42.4442 13.3333 32.6667Z"
						fill={props.iconColor}
					/>
				</svg>
			)}
			{props.type === "phone" && (
				<svg
					width="80"
					height="80"
					viewBox="0 0 80 80"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M11.5847 12.4233C15.1605 7.41704 22.4936 7.11619 26.4675 11.8127L32.823 19.3237C34.6665 21.5023 34.9187 24.6127 33.4504 27.0599L28.5544 35.2199C28.9469 35.8621 29.4787 36.6286 30.159 37.5027C31.3723 39.0616 32.9225 40.7921 34.6513 42.5209C37.5626 45.4323 40.822 48.1813 43.6276 50.039L51.6288 45.2383C54.076 43.77 57.1864 44.0222 59.365 45.8657L67.2385 52.5279C71.7511 56.3462 71.462 63.3922 66.6518 66.828C59.2339 72.1266 49.2641 73.3866 41.0958 68.4014C35.4648 64.9646 28.5647 60.1129 22.3918 53.9399C16.6689 48.217 12.4961 42.2844 9.58576 37.3495C4.79146 29.22 6.47615 19.5752 11.5847 12.4233ZM21.3783 16.119C20.2118 14.7403 18.0592 14.8287 17.0095 16.2982C13.0382 21.8581 12.187 28.6365 15.3282 33.9629C18.0161 38.5207 21.8594 43.9794 27.1058 49.2258C32.787 54.907 39.2192 59.4458 44.5689 62.7108C50.0814 66.0752 57.1527 65.4204 62.7769 61.4032C64.0504 60.4935 64.1269 58.628 62.9322 57.6171L55.0587 50.9549L45.32 56.7982C44.2889 57.4168 43.0046 57.4322 41.959 56.8385C38.1313 54.6649 33.6574 50.9551 29.9372 47.235C28.059 45.3568 26.316 43.4193 24.898 41.5973C23.5194 39.826 22.2994 37.9767 21.6434 36.2952C21.2684 35.334 21.3597 34.2534 21.8905 33.3687L27.7337 23.63L21.3783 16.119Z"
						fill={props.iconColor}
					/>
				</svg>
			)}
			<div className="pt-3 text-lg text-center">
				{typeof props.detail === "string"
					? props.detail
					: props.detail.map((contact, index) => (
						<p
							key={contact}
							className={index % 2 === 0 ? "font-bold" : "tablet:pb-4"}
						>
							{contact}
						</p>
					))}
				<br></br> {props.detail2}
			</div>
		</div>
	);
}

function Team() {
	return (
		<section id="team" className="flex w-full flex-col">
			<h1
				className="text-6xl font-bold"
				style={{ color: Config.styles.primaryColor }}
			>
				Our Team
			</h1>
			<br />
			<div className="grid grid-cols-1 tablet:grid-cols-4 gap-0 tablet:gap-4">
				{Config.location.teamProfile.map((member) => (
					<TeamProfile
						key={member.name}
						name={member.name}
						position={member.position}
						phone={member.phone}
						email={member.email}
						img={member.img}
					></TeamProfile>
				))}
			</div>
			<br />
			<AddressSection />
		</section>
	);
}

function TeamProfile(props) {
	return (
		<div className="flex flex-col py-10 px-10  items-center justify-center">
			<img
				className="h-40 w-40 rounded-full"
				src={props.img}
				alt="profile"
			></img>
			<br></br>
			<p className="text-center font-bold">{props.name}</p>
			<p className="text-center text-sm">{props.position}</p>
			<p className="text-center text-sm">{props.phone}</p>
			<p className="text-center text-sm">{props.email}</p>
		</div>
	);
}

function AddressSection() {
	return (
		<>
			<h1
				className="text-6xl font-bold"
				style={{ color: Config.styles.primaryColor }}
			>
				Locations
			</h1>
			<br />
			{Config.location.addresses.map((address) => (
				<div className="flex flex-col tablet:flex-row justify-between py-4">
					<div className="flex flex-col items-center justify-center tablet:w-1/4">
						<h3 className="text-center font-bold">{address.title}</h3>
						<br />
						<p className="text-center">{address.address1}</p>
						<p className="text-center">{address.address2}</p>
					</div>
					<br className="tablet:hidden" />
					<div className="flex rounded-3xl overflow-clip tablet:w-3/4">
						<CustomMap coordinate={address.coordinate} />
					</div>
				</div>
			))}
		</>
	);
}

export default Home;
