import BusinessLogo from "../../assets/images/logos/stLawrenceGrain/SLG-Logo.png";
import HomepageBackground from "../../assets/images/StLawrenceGrain/homepageImage.jpeg";
import CashBidBackground from "../../assets/images/StLawrenceGrain/ourStoryImage.jpg";

const styles = {
	mainColor: "rgba(0,153,102,1.0)",
	backgroundColor: "#F2F3F3",
	btnTextColor: "#000",
	primaryColor: "#1A6833",
	primaryVariant: "#D2DDBC",
	secondaryColor: "#191C19",
	secondaryVariant: "#CBE2DE",
	surface: "#FFF",
	homeFontColor: "#FFF",
	transparentMainColor: "rgba(0,153,102,0.5)",
	logo: BusinessLogo,
	isLogoSquared: true,
	homepageBackground: HomepageBackground,
	ourStoryImg: CashBidBackground,
};

export default styles;
